import React, { useEffect, useState } from 'react'
import { MONTH_SHORT_TEXT } from '../../constants/score-card.constants';
import s from "../../components/DateDropdown/DateDropdown.module.css";
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DatePicker, Space } from 'antd';

const { MonthPicker } = DatePicker;

const DateRangePicker = ({ fromDate, toDate, setFromDate, setToDate }) => {

    const [showFromYearFilter, setShowFromYearFilter] = useState(false);
    const [showToYearFilter, setShowToYearFilter] = useState(false);

    const isFromLeftDisabled = fromDate.month === 1;
    const isFromRightDisabled = fromDate.year === toDate.year && fromDate.month === toDate.month;
    const isToLeftDisabled = toDate.year === fromDate.year && toDate.month === fromDate.month;
    const isToRightDisabled = toDate.month === 12;

    const handleFromDateSelect = (date) => {
        if (date) {
            const newYear = date.year();
            const newMonth = date.month() + 1;

            setFromDate({ year: newYear, month: newMonth });

            if (newYear !== toDate.year) {
                setToDate({ year: newYear, month: 12 });
            }

            setShowFromYearFilter(false);
        }
    };

    const handleToDateSelect = (date) => {
        if (date) {
            const newYear = date.year();
            const newMonth = date.month() + 1;

            setToDate({ year: newYear, month: newMonth });

            if (newYear !== fromDate.year) {
                setFromDate({ year: newYear, month: 1 });
            }

            setShowToYearFilter(false);
        }
    };

    const makeText = (m) => {
        if (m && m.year && m.month)
            return MONTH_SHORT_TEXT[m.month - 1] + " - " + m.year;
        return "?";
    };

    const decreaseFromDate = () => {
        if (!isFromLeftDisabled) {
            setFromDate((prev) => {
                let newMonth = prev.month - 1;
                let newYear = prev.year;

                if (newMonth < 1) {
                    newMonth = 12;
                    newYear -= 1;
                }

                return { year: newYear, month: newMonth };
            });
        }
    };

    const increaseFromDate = () => {
        if (!isFromRightDisabled) {
            setFromDate((prev) => {
                let newMonth = prev.month + 1;
                let newYear = prev.year;

                if (newMonth > 12) {
                    newMonth = 1;
                    newYear += 1;
                }

                return { year: newYear, month: newMonth };
            });
        }
    };

    const decreaseToDate = () => {
        if (!isToLeftDisabled) {
            setToDate((prev) => {
                let newMonth = prev.month - 1;
                let newYear = prev.year;

                if (newMonth < 1) {
                    newMonth = 12;
                    newYear -= 1;
                }
                
                return { year: newYear, month: newMonth };
            });
        }
    };

    const increaseToDate = () => {
        if (!isToRightDisabled) {
            setToDate((prev) => {
                let newMonth = prev.month + 1;
                let newYear = prev.year;

                if (newMonth > 12) {
                    newMonth = 1;
                    newYear += 1;
                }

                return { year: newYear, month: newMonth };
            });
        }
    };

    return (
        <div className={s.filterBlocks}>
            <div className={s.filterBlock}>
                <span>FROM</span>
                <div
                    className={`${s.filterBlockDropdown}`}
                    onBlur={() => setShowFromYearFilter(false)}
                >
                    <div className={s.buttonContainer}>
                        <CaretLeftOutlined
                            onClick={decreaseFromDate} 
                            style={isFromLeftDisabled ? { color: 'grey', cursor: 'not-allowed' } : {}}
                        />
                        <button
                            type='button'
                            onClick={() =>
                                setShowFromYearFilter(!showFromYearFilter)
                            }
                        >
                            {makeText(fromDate)}
                        </button>
                        <CaretRightOutlined
                            onClick={increaseFromDate}
                            style={isFromRightDisabled ? { color: 'grey', cursor: 'not-allowed' } : {}}
                        />
                    </div>
                    <div className='scorecards-month-picker'>
                        <Space direction='vertical'>
                            <MonthPicker
                                value={moment(
                                    `${fromDate.year}-${fromDate.month}`,
                                    'YYYY-MM'
                                )}
                                onChange={handleFromDateSelect}
                                open={showFromYearFilter}
                                disabledDate={(current) => {
                                    return current.format('YYYY') < '2024' || (
                                        current &&
                                        current.isAfter(moment(`${toDate.year}-${toDate.month}`, 'YYYY-MM'))
                                    );
                                }}
                            />
                        </Space>
                    </div>
                </div>
            </div>

            <div className={s.filterBlock}>
                <span>TO</span>
                <div
                    className={`${s.filterBlockDropdown}`}
                    onBlur={() => setShowToYearFilter(false)}
                >
                    <div className={s.buttonContainer}>
                    <CaretLeftOutlined
                            onClick={decreaseToDate}
                            style={isToLeftDisabled ? { color: 'grey', cursor: 'not-allowed' } : {}}
                        />
                        <button
                            type='button'
                            onClick={() =>
                                setShowToYearFilter(!showToYearFilter)
                            }
                        >
                            {makeText(toDate)}
                        </button>
                        <CaretRightOutlined
                            onClick={increaseToDate}
                            style={isToRightDisabled ? { color: 'grey', cursor: 'not-allowed' } : {}}
                        />
                    </div>
                    <div className='scorecards-month-picker'>
                        <Space direction='vertical'>
                            <MonthPicker
                                value={moment(
                                    `${toDate.year}-${toDate.month}`,
                                    'YYYY-MM'
                                )}
                                onChange={handleToDateSelect}
                                open={showToYearFilter}
                                disabledDate={(current) => {
                                    return current.format('YYYY') < '2024' || (
                                        current &&
                                        current.isBefore(moment(`${fromDate.year}-${fromDate.month}`, 'YYYY-MM'))
                                    );
                                }}
                            />
                        </Space>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DateRangePicker