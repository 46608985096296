import * as types from './actionType';

export const fetchRiskTop = (payload?: any) => ({
    type: types.GET_RISK_TOP_REQUESTED,
    payload
});

export const fetchAvailablePeriodRequest = () => ({
    type: types.GET_RISK_AVAILABLE_PERIOD_REQUESTED,
  });
  
export const fetchAvailablePeriodSuccess = (data) => ({
  type: types.GET_RISK_AVAILABLE_PERIOD_SUCCESS,
  payload: data,
});

export const fetchRiskKRICallout = (data) => ({
  type: types.GET_RISK_KRI_CALLOUT_REQUESTED,
  payload: data,
});

export const fetchRiskKRICalloutSuccess = (data) => ({
  type: types.GET_RISK_KRI_CALLOUT_SUCCESS,
  payload: data,
});

export const fetchRiskMitigationCallout = (data) => ({
  type: types.GET_RISK_MITIGATION_CALLOUT_REQUESTED,
  payload: data,
});

export const fetchRiskMitigationCalloutSuccess = (data) => ({
  type: types.GET_RISK_MITIGATION_CALLOUT_SUCCESS,
  payload: data,
});

export const fetchGasBusinessFLA = (data) => ({
  type: types.GET_GAS_BUSINESS_FLA_REQUESTED,
  payload: data,
});

export const fetchGasBusinessFLASuccess = (data) => ({
  type: types.GET_GAS_BUSINESS_FLA_SUCCESS,
  payload: data,
});

export const fetchLNGAFLA = (data) => ({
  type: types.GET_LNGA_FLA_REQUESTED,
  payload: data,
});

export const fetchLNGAFLASuccess = (data) => ({
  type: types.GET_LNGA_FLA_SUCCESS,
  payload: data,
});

export const fetchGPFLA = (data) => ({
  type: types.GET_GP_FLA_REQUESTED,
  payload: data,
});

export const fetchGPFLASuccess = (data) => ({
  type: types.GET_GP_FLA_SUCCESS,
  payload: data,
});

export const fetchLMTFLA = (data) => ({
  type: types.GET_LMT_FLA_REQUESTED,
  payload: data,
});

export const fetchLMTFLASuccess = (data) => ({
  type: types.GET_LMT_FLA_SUCCESS,
  payload: data,
});

export const fetchGCOFLA = (data) => ({
  type: types.GET_GCO_FLA_REQUESTED,
  payload: data,
});

export const fetchGCOFLASuccess = (data) => ({
  type: types.GET_GCO_FLA_SUCCESS,
  payload: data,
});

export const fetchGasBusinessSLA = (data) => ({
  type: types.GET_GAS_BUSINESS_SLA_REQUESTED,
  payload: data,
});

export const fetchGasBusinessSLASuccess = (data) => ({
  type: types.GET_GAS_BUSINESS_SLA_SUCCESS,
  payload: data,
});

export const fetchLNGASLA = (data) => ({
  type: types.GET_LNGA_SLA_REQUESTED,
  payload: data,
});

export const fetchLNGASLASuccess = (data) => ({
  type: types.GET_LNGA_SLA_SUCCESS,
  payload: data,
});

export const fetchGPSLA = (data) => ({
  type: types.GET_GP_SLA_REQUESTED,
  payload: data,
});

export const fetchGPSLASuccess = (data) => ({
  type: types.GET_GP_SLA_SUCCESS,
  payload: data,
});

export const fetchLMTSLA = (data) => ({
  type: types.GET_LMT_SLA_REQUESTED,
  payload: data,
});

export const fetchLMTSLASuccess = (data) => ({
  type: types.GET_LMT_SLA_SUCCESS,
  payload: data,
});

export const fetchGCOSLA = (data) => ({
  type: types.GET_GCO_SLA_REQUESTED,
  payload: data,
});

export const fetchGCOSLASuccess = (data) => ({
  type: types.GET_GCO_SLA_SUCCESS,
  payload: data,
});

export const fetchGasBusinessTLA = (data) => ({
  type: types.GET_GAS_BUSINESS_TLA_REQUESTED,
  payload: data,
});

export const fetchGasBusinessTLASuccess = (data) => ({
  type: types.GET_GAS_BUSINESS_TLA_SUCCESS,
  payload: data,
});

export const fetchLNGATLA = (data) => ({
  type: types.GET_LNGA_TLA_REQUESTED,
  payload: data,
});

export const fetchLNGATLASuccess = (data) => ({
  type: types.GET_LNGA_TLA_SUCCESS,
  payload: data,
});

export const fetchGPTLA = (data) => ({
  type: types.GET_GP_TLA_REQUESTED,
  payload: data,
});

export const fetchGPTLASuccess = (data) => ({
  type: types.GET_GP_TLA_SUCCESS,
  payload: data,
});

export const fetchLMTTLA = (data) => ({
  type: types.GET_LMT_TLA_REQUESTED,
  payload: data,
});

export const fetchLMTTLASuccess = (data) => ({
  type: types.GET_LMT_TLA_SUCCESS,
  payload: data,
});

export const fetchGCOTLA = (data) => ({
  type: types.GET_GCO_TLA_REQUESTED,
  payload: data,
});

export const fetchGCOTLASuccess = (data) => ({
  type: types.GET_GCO_TLA_SUCCESS,
  payload: data,
});

export const fetchLNGAAssetCallout = (data) => ({
  type: types.GET_LNGA_ASSET_CALLOUT_REQUESTED,
  payload: data,
});

export const fetchLNGAAssetCalloutSuccess = (data) => ({
  type: types.GET_LNGA_ASSET_CALLOUT_SUCCESS,
  payload: data,
});

export const fetchLMTAssetCallout = (data) => ({
  type: types.GET_LMT_ASSET_CALLOUT_REQUESTED,
  payload: data,
});

export const fetchLMTAssetCalloutSuccess = (data) => ({
  type: types.GET_LMT_ASSET_CALLOUT_SUCCESS,
  payload: data,
});

export const fetchGCOAssetCallout = (data) => ({
  type: types.GET_GCO_ASSET_CALLOUT_REQUESTED,
  payload: data,
});

export const fetchGCOAssetCalloutSuccess = (data) => ({
  type: types.GET_GCO_ASSET_CALLOUT_SUCCESS,
  payload: data,
});

export const fetchGPAssetCallout = (data) => ({
  type: types.GET_GP_ASSET_CALLOUT_REQUESTED,
  payload: data,
});

export const fetchGPAssetCalloutSuccess = (data) => ({
  type: types.GET_GP_ASSET_CALLOUT_SUCCESS,
  payload: data,
});
