import React, { useEffect, useState, useRef } from 'react';

import { Desktop } from '../../responsive/responesive';
import { Select, Space } from 'antd';
import OverallChart from './OverallChart/OverallChart';
import FirstLineActionItemComponent from '../../container/RiskManagement/MyAssurance/AssuranceFirstLine/FirstLineActionItemComponent';
import s from './AssuranceCallout.module.css';
import ChecklistDetailsAccordion from './ChecklistDetailsAccordion/ChecklistDetailsAccordion';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGCOAssetCallout, fetchGPAssetCallout, fetchLMTAssetCallout, fetchLNGAAssetCallout } from '../../action/actionRisk';
import { get } from 'lodash';

const AssuranceCallout = ({ record, onClose, opu }) => {  
	const [showAssetFilter, setShowAssetFilter] = useState(false);
	const [selectedLineTab, setSelectedLineTab] = useState<number>(0);
	const [selectedCompTab, setSelectedCompTab] = useState<number>(0);
	const [selectedAsset, setSelectedAsset] = useState(record.assetName);
	const [isExiting, setIsExiting] = useState(false);
	const calloutRef = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (selectedAsset) {
			switch (opu) {
				case 1:
					dispatch(fetchLNGAAssetCallout(`?assetName=${selectedAsset}`));
					break;
				case 2:
					dispatch(fetchGPAssetCallout(`?assetName=${selectedAsset}`));
					break;
				case 3:
					dispatch(fetchLMTAssetCallout(`?assetName=${selectedAsset}`));
					break;
				case 4:
					dispatch(fetchGCOAssetCallout(`?assetName=${selectedAsset}`));
					break;
				default:
					break;
			}
		}
	}, [dispatch, opu, selectedAsset]);

	const calloutData = useSelector((state) =>
		get(state, 'risk.assuranceAssetCallout.result', [])
	);

	const assetDropdown = useSelector((state) =>
		get(state, 'risk.myAssuranceInfo.result.assuranceRequirementCompliance.requirementCompAssetBreakdowns', [])
	);

	const handleChangeLineTab = (tab: number) => {
	setSelectedLineTab(tab);
	};

	const handleChangeCompTab = (tab: number) => {
	setSelectedCompTab(tab);
	};

	const handleChangeAsset = (value) => {
		setSelectedAsset(value);
	};

	const calloutClassName = `${s.calloutContent}`;

	const assuranceLineTabArr = [
		{ label: "FLA", value: 0 },
		{ label: "SLA", value: 1 },
		{ label: "TLA", value: 2 },
	];

	const compTabArr = [
		{ label: "Requirement Compliance", key: "requirement", value: 0 },
		{ label: "Schedule Compliance", key: "compliance", value: 1 },
	];

	const handleClose = () => {
		setIsExiting(true);
		setTimeout(() => {
			onClose();
			setIsExiting(false);
		}, 300);
	};

	const CloseButton = () => {
		return (
				<button
					type='button'
					onClick={handleClose}
					className={s.callOutCloseBtn}
				>
					<img alt='Close' src='/img/icon/ic-close-secondary.svg' />
				</button>
		);
	};

	function containerClassName(cssModule) {
	return `${cssModule.assuranceCalloutContainer} ${cssModule.show}`;
	}

	return (
	<>
		<div>
			<div className={`${s.overlay} ${isExiting ? s.fadeOut : s.fadeIn}`}></div>
			<Desktop>
				<div ref={calloutRef} className={`${containerClassName(s)} ${s.assuranceCalloutContainer} ${isExiting ? s.slideOut : s.slideIn}`}>
					<div className={calloutClassName}>
						<div className={s.calloutHeader}>
							<div className={s.headerTitle}>
								<div className={s.filterContainer}>
									<div className={s.filterBlocks}>
										<div className={s.filterBlock}>
											<span>ASSET</span>
											<div
												className={s.filterBlockDropdown}
												onBlur={(e) => {
													if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget)) {
														setShowAssetFilter(false);
													}
												}}
											>
												<button
													type="button"
													className={s.dropdownButton}
													onClick={() => setShowAssetFilter((prev) => !prev)}
												>
													{selectedAsset}
													<i className='mdi mdi-menu-down'></i>
												</button>
												{showAssetFilter && (
													<div className={s.assetSelect}>
														<Space direction="vertical">
															<Select
																className={s.mapRightPanelSelect}
																value={selectedAsset}
																onChange={handleChangeAsset}
																open={showAssetFilter}
																onDropdownVisibleChange={(open) => setShowAssetFilter(open)}
																dropdownStyle={{
																	padding: '0',
																	background: 'linear-gradient(180deg,rgba(6, 25, 25, 0.9) 0%,#000000 100%)',
																}}
															>
																{assetDropdown.map((item, idx) => (
																	<Select.Option
																		value={item.assetName}
																		key={idx}
																		className="score-card-OPU-selection-item"
																	>
																		{item.assetName}
																	</Select.Option>
																))}
															</Select>
														</Space>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
								<CloseButton />
							</div>
						</div>
						<div className={s.opuTab}>
							<ul>
								{assuranceLineTabArr.map((v, i) => {
									const isDisabled = i !== 0;
									return (
										<li
											key={i}
											onClick={() => {
												if (!isDisabled) handleChangeLineTab(v.value);
											}}
											onKeyDown={(e) => {
												if (!isDisabled && e.key === 'Enter') {
													handleChangeLineTab(v.value);
												}
											}}
											tabIndex={isDisabled ? -1 : 0}
											className={`${selectedLineTab === v.value ? s.active : ''} ${isDisabled ? s.disabled : ''}`}
										>
											{v.label}
										</li>
									);
								})}
							</ul>
						</div>
						<div className={s.sustainabilityCallOutBody}>
							<div className={`${s.opuTab} ${s.compTab}`}>
								<ul>
									{compTabArr.map((v, i) => {
										const isDisabled = i === 1;
										return (
											<li
												key={i}
												onClick={() => {
													if (!isDisabled) handleChangeCompTab(v.value);
												}}
												onKeyDown={(e) => {
													if (!isDisabled && e.key === 'Enter') {
														handleChangeCompTab(v.value);
													}
												}}
												tabIndex={isDisabled ? -1 : 0}
												className={`${selectedCompTab === v.value ? s.active : ''} ${isDisabled ? s.disabled : ''}`}
											>
												{v.label}
											</li>
										);
									})}
								</ul>
							</div>
							<div className={s.calloutValues}>
								<OverallChart overall={calloutData.overall}/>
								<FirstLineActionItemComponent />
							</div>
							<div className={s.accordionContainer}>
								<h2>Non-Compliance by Assurance Category</h2>
								<ChecklistDetailsAccordion />
							</div>
						</div>
					</div>
				</div>
			</Desktop>
		</div>
	</>
	);
};
export default AssuranceCallout;
