import s from "./AssuranceComponent.module.css";
import { useEffect, useState, useMemo } from "react";
import { get, set } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb } from "antd";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import AssuranceFirstLineComponent from "../../../container/RiskManagement/MyAssurance/AssuranceFirstLine/AssuranceFirstLineComponent";
import AssuranceThirdLineComponent from "../../../container/RiskManagement/MyAssurance/AssuranceThirdLine/AssuranceThirdLineComponent";
import moment from "moment";
import AssuranceSecondLineComponent from "./AssuranceSecondLine/AssuranceSecondLineComponent";

const AssuranceComponent = () => {
	const [isLoaded, setIsLoaded] = useState(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [fromDate, setFromDate] = useState({
    year: moment().year(),
    month: 1,
  });

  const [toDate, setToDate] = useState({
    year: moment().year(),
    month: moment().month() + 1,
  });

  const [activeLine, setActiveLine] = useState<number>(0);

  const handleChangeTab = (tab: number) => {
    setSelectedTab(tab);
  };

  const handleLineClick = (line: number) => {
    setActiveLine(line);
  };

  const handleLoad = () => {
    setIsLoaded(true);
  };

  const breadCrumb = () => {
    return (
      <Breadcrumb
        className={s.mapBreadcrumb}
        separator={
          <img
            className={s.breadcrumbSeparator}
            alt='separator-icon'
            src='/img/icon/arrow-right.svg'
          />
        }
      >
        <div className={s.mapBreadcrumbItem}>
          <span>Gas Assurance Performance</span>
        </div>
      </Breadcrumb>
    );
  };

  const opuTabArr = [
    { label: "Gas Business", value: 0 },
    { label: "LNGA", value: 1 },
    { label: "G&P", value: 2 },
    { label: "LMT", value: 3 },
    { label: "CO", value: 4 }
  ];

  const renderLineComponent = () => {
    switch (activeLine) {
      case 0:
        return <AssuranceFirstLineComponent data={{opu: selectedTab, line: activeLine}} onChangeTab={handleChangeTab} opuTabArr={opuTabArr} fromDate={fromDate} toDate={toDate}/>;
      case 1:
        return <AssuranceSecondLineComponent data={{opu: selectedTab, line: activeLine}} onChangeTab={handleChangeTab} opuTabArr={opuTabArr} fromDate={fromDate} toDate={toDate}/>;
      case 2:
        return <AssuranceThirdLineComponent data={{opu: selectedTab, line: activeLine}} onChangeTab={handleChangeTab} opuTabArr={opuTabArr} fromDate={fromDate} toDate={toDate}/>;
      default:
        return null;
    }
  };

  return (
    <div onLoad={handleLoad} className={`fullscreen-pt-0 ${s.assuranceRoot}`}>
      <div className={`page-container bg-risk-management ${s.assuranceContainer}`}>
        <div className={s.assurancePanel}>
          <div className={s.assurancePanelHeader}>
            {breadCrumb()}
            {/* <DateRangePicker currentDate={currentDate} onDateChange={onDateChange} /> */}
            <DateRangePicker fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
          </div>
          <div className={s.assurancePanelBody}>
            <div className={s.opuTab}>
              <ul>
                {opuTabArr.map((v, i) => {
                  return (
                    <li key={i} onClick={() => {handleChangeTab(v.value);}}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleChangeTab(v.value);
                        }
                      }}
                      tabIndex={0}
                      className={selectedTab === v.value ? s.active : ''}
                    >
                      {v.label}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={s.assurancePanelContent}>
              <div className={s.threeLines}>
                <span>Assurance Line</span>
                <div className={s.lineEntryContainer}>
                  <div className={`${s.lineEntry} ${activeLine === 0 ? s.active : ""}`} onClick={() => handleLineClick(0)}>
                    First Line
                  </div>
                  {/* <div className={`${s.lineEntry} ${activeLine === 1 ? s.active : ""}`} onClick={() => handleLineClick(1)}>
                    Second Line
                  </div>
                  <div className={`${s.lineEntry} ${activeLine === 2 ? s.active : ""}`} onClick={() => handleLineClick(2)}>
                    Third Line
                  </div> */}
                </div>
              </div>
              {renderLineComponent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssuranceComponent;